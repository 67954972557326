import React, {Component} from "react";
import {
		Table,
		Dropdown
} from "react-bootstrap";
import { listAllStaffEngagements, listAllStaffEngagementsReport, listLocations, viewAdminLocationOverviewData, viewLocationNpsData, viewCustomLocationNpsDataByLocation, viewAdminDashboardEndorsementsReports } from "../../../actions";
import "./../ReportsPage/ReportsPage.scss";
import Spinner from "react-spinner";
import "react-spinner/react-spinner.css";
import {connect} from "react-redux";
import StaffBadges from "./../../Utilities/StaffBadges";
import dateFormat from "dateformat";
import DayPicker from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import {PieChart, Pie, Legend, Cell, ResponsiveContainer, Label} from 'recharts';
// Include the locale utils designed for moment
import MomentLocaleUtils, {formatDate, parseDate} from 'react-day-picker/moment';
// Make sure moment.js has the required locale data
import 'moment/locale/es';
import 'react-day-picker/lib/style.css';


class LocationSpecificReport extends Component {

		constructor(props) {
				super(props);
				this.props.listLocations(this.props.tvClient);
				this.state = {
						emptySet: [{'name':'No Data','value': 1}],
						perpage: 10,
						filter: '',
						sort: null,
						sortDirection: 'desc',
						currentFilter: 8,
						activeKey: 1,
						dateFilter: 'thismonth',
						locationFilter: 0,
						currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ',
						currentFilterLocation: ' Choose One ',
						showCustomDate: false,
						showLocations: true,
						reportFrom: null,
						reportTo: null,
						currentDate: new Date().toLocaleString('en-us', { month: 'long' })
				};

				this.handleFromChange = this.handleFromChange.bind(this);
				this.handleToChange = this.handleToChange.bind(this);
				this.runReport = this.runReport.bind(this);

		}

		renderScoreToName(score){
			let text = '-'
			if(score.toString()==='10'){
			  text = 'Excellent';
			} else if(score.toString()==='9'){
				text = 'Very Good';
			} else if(score.toString()==='8'){
				text = 'Average';
			} else if(score.toString()==='7'){
				text = 'Dissatisfied';
			} else if(score.toString()==='6'){
				text = 'Very Dissatisfied';
			} else if(score.toString()==='5'){
				text = 'Excellent';
			} else if(score.toString()==='4'){
				text = 'Very Good';
			} else if(score.toString()==='3'){
				text = 'Average';
			} else if(score.toString()==='2'){
				text = 'Dissatisfied';
			} else if(score.toString()==='1'){
				text = 'Very Dissatisfied';
			}
	
	
			if(this.props.locationNpsMax!==undefined){
			  if(parseInt(this.props.locationNpsMax)>5){
	
				if (score.toString() === '10') {
				  text = 'Exceptional';
				} else if (score.toString() === '9') {
				  text = 'Excellent';
				} else if (score.toString() === '8') {
				  text = 'Very Good';
				} else if (score.toString() === '7') {
				  text = 'Good';
				} else if (score.toString() === '6') {
				  text = 'Above Average';
				} else if (score.toString() === '5') {
				  text = 'Average';
				} else if (score.toString() === '4') {
				  text = 'Below Average';
				} else if (score.toString() === '3') {
				  text = 'Poor';
				} else if (score.toString() === '2') {
				  text = 'Very Poor';
				} else if (score.toString() === '1') {
				  text = 'Unsatisfactory';
				}
	
			  }
			}
	
			return text;
		}

		handleFromChange(from) {
			this.setState({ reportFrom: from });
		}
		handleToChange(to) {
			this.setState({ reportTo: to });
		}

		runReport(){
			this.setState({ dateFilter: 'custom', currentFilter: 6, currentFilterName: ' Date Range (' + dateFormat(this.state.reportFrom, "shortDate") + ' to ' + dateFormat(this.state.reportTo, "shortDate") + ') ' });
			//this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
			this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
			this.props.viewCustomLocationNpsDataByLocation(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.locationFilter);
			this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
			this.props.viewAdminLocationOverviewData(this.props.accessToken, 'custom', this.state.locationFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
		}

		handleLocationFilter(selectedKey){

				for (var i = this.props.locations.length - 1; i >= 0; i--) {
					if(this.props.locations[i].location_id===parseInt(selectedKey)){
						this.setState({ locationFilter: selectedKey, currentFilterLocation: ' ' + this.props.locations[i].name + ' ' });
						if(this.state.dateFilter==='custom'){
								this.props.viewCustomLocationNpsDataByLocation(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"),selectedKey);
								this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
								this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
								this.props.viewAdminLocationOverviewData(this.props.accessToken, 'custom', selectedKey, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
						} else{
								this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
								this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, selectedKey, this.state.dateFilter, null, null);
								this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, selectedKey, null, null);
								this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, selectedKey, this.state.dateFilter, null, null);
						}

						break;
					}
				}

		}

		handleFilter(selectedKey) {
			if(selectedKey==1){
				this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' All Time ' }, () => {
					this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
					this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
				});
			} else if(selectedKey==2){
				this.setState({ dateFilter: 'thismonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' This Month (' + new Date().toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
					this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
					this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
				});
			} else if(selectedKey==3){
				this.setState({ dateFilter: 'lastmonth', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Month (' + new Date(new Date().getFullYear(), new Date().getMonth()-1).toLocaleString('en-us', { month: 'long' }) + ') ' }, () => {
					this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
					this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null,null);
					this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
				});
			} else if(selectedKey==4){
				this.setState({ dateFilter: 'yeartodate', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Year To Date (' + new Date().toLocaleString('en-us', { year: 'numeric' }) + ') ' }, () => {
					this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
					this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
				});
			} else if(selectedKey==5){
				this.setState({ dateFilter: 'lastyear', showCustomDate: false, currentFilter: selectedKey, currentFilterName: ' Last Year (' + new Date(new Date().getFullYear()-1, 1).toLocaleString('en-us', { year: 'numeric' }) + ') ' } , () => {
					this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
					this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
				});
			} else if(selectedKey==6){
				this.setState({ showCustomDate: true });
			}
			if(selectedKey==8){
				this.setState({ dateFilter: 'alltime', showCustomDate: false, currentFilterName: ' All Time ', currentFilter: selectedKey }, () => {
					this.props.viewLocationNpsData(this.props.accessToken, this.state.dateFilter);
					this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, 1, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
					this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter, null, null);
				});

			}
		}

		componentDidMount() {
				this.changeListPage(1);
		}

		changeListPage(page) {
				if(this.state.dateFilter==='custom'){
						this.props.viewCustomLocationNpsDataByLocation(this.props.accessToken, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"), this.state.locationFilter);
						this.props.viewAdminDashboardEndorsementsReports(this.props.accessToken, this.state.locationFilter, 'custom', dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
						this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
						this.props.viewAdminLocationOverviewData(this.props.accessToken, this.state.dateFilter, this.state.locationFilter,  dateFormat(this.state.reportFrom, "shortDate"), dateFormat(this.state.reportTo, "shortDate"));
				} else{
						this.props.listAllStaffEngagementsReport(this.props.tvClient, 'and', this.state.filter, this.state.sort, page, this.state.perpage, this.state.locationFilter, this.state.dateFilter, null, null);
				}

		}

		render() {
			 return <div>
						<h1 className="page-header">
								Location Specific Report

										<div className="float-right">
										<Dropdown
													title='Filter Report By Date'
													id='filterSurvey'
													drop="down" onSelect={this.handleFilter.bind(this)}
												>
													<Dropdown.Toggle className="btn-default" id="dropdown-basic">
														Filter Report By Date
													</Dropdown.Toggle>
													<Dropdown.Menu>
													<Dropdown.Item eventKey="1">All Time</Dropdown.Item>
													<Dropdown.Item eventKey="2">This Month</Dropdown.Item>
													<Dropdown.Item eventKey="3">Last Month</Dropdown.Item>
													<Dropdown.Item eventKey="4">YTD</Dropdown.Item>
													<Dropdown.Item eventKey="5">Last Year</Dropdown.Item>
													<Dropdown.Item eventKey="6">Custom</Dropdown.Item>
													<Dropdown.Divider />
													<Dropdown.Item eventKey="8">Reset Filter</Dropdown.Item>
													</Dropdown.Menu>
												</Dropdown>
											</div>

						 {(this.state.showLocations && this.props.locations) && <div className="float-right locationSpecificReportFilter">
										<Dropdown
													title='Choose a Location'
													id='filterSurvey'
													drop="down" onSelect={this.handleLocationFilter.bind(this)}
												>
													<Dropdown.Toggle className="btn-default" id="dropdown-basic">
														Choose a Location
													</Dropdown.Toggle>
													<Dropdown.Menu>
											{Object.values(this.props.locations).map(d => {
												if(this.props.isLocationOnly || this.props.isSurveysReportsOnly){
															if(this.props.roleLocations!==undefined){
																	for(var i=0; i<this.props.roleLocations.length;i++){
																			if(this.props.roleLocations[i].toString()===d.location_id.toString()){
																					return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
																			}
																	}
															} else{
																	return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
															}
													} else{
															return  <Dropdown.Item eventKey={d.location_id} key={d.location_id}>{d.name}</Dropdown.Item>
													}
											})}
											</Dropdown.Menu>
										</Dropdown>
						</div> }

							{this.state.showCustomDate && <div className="customDateRange">
									<p className="prizeDrawTitle">Select the range to run the report for:</p>
									<label className="dayPickerLabel">From:</label><DayPickerInput formatDate={formatDate} id="fromDate" name="fromDate" onDayChange={this.handleFromChange}
										dayPickerProps={{
											selectedDays: this.state.selectedDay,
											disabledDays: {
												after: new Date(),
											},
										}} />

								<label className="dayPickerLabel">To:</label><DayPickerInput formatDate={formatDate} id="toDate" name="toDate" onDayChange={this.handleToChange}
										dayPickerProps={{
											selectedDays: this.state.selectedDay,
											disabledDays: {
												after: new Date(),
												before: this.state.prizeFrom
											},
										}} />

							<button className="btn btn-primary" onClick={this.runReport}>{this.props.runningReport ? 'Running...' : 'Run Report'}</button>
							</div>
						}

						</h1>

						{ this.state.locationFilter === 0 && <h3 className="case-list-container">Please choose a location to begin</h3>}
						{ this.state.locationFilter !== 0 && <div>
						<p className="case-list-container stand-out-line float-right">Showing data for <span className="text-underline">{this.state.currentFilterName}</span> at <span className="text-underline">{this.state.currentFilterLocation}</span></p>
						<div className="case-list-container">
						{this.props.locationNpsLoading && <Spinner/>}
							{ this.props.locationNpsData && <div className="reportingPages">
																							{ this.props.locationNpsData.some( location => location.placeName.trim() === this.state.currentFilterLocation.trim() ) && <div>
																											{this.props.locationNpsData.map((c, index) => {
																											if(c.placeName.trim()===this.state.currentFilterLocation.trim()){
																											return <div className="locationSpecificReportPieSpace piechart col-md-12 reportBlockspacer" key={index}><div className="col-md-4 nps-survey-container float-left">
																												 {this.props.locationNpsLoading && <Spinner/>}
																													 <div>
																															<h5>{c.placeName} Score</h5>
																														 <PieChart width={350} height={250} >
																														 <Pie isAnimationActive={false} data={c} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#8884d8" >
																															{

																																c.map((entry, index2) =>  {
																																					return <Cell key={entry.value} fill={ entry.color }/>
																																	})
																															}
																															<Label width={100} position="center">{c.scoreText}</Label>
																														 </Pie>
																															<Legend layout="vertical" align="right" verticalAlign="middle" />
																														 </PieChart>
																															</div>
																												</div> <div className="col-md-7 nps-survey-container float-right">

																															<h5>NPS Survey Responses</h5>
																															<table className="npsSurveyTable">
																																	<tbody>

																																							<tr>
																																									<th>Value</th>
																																									<th>Name</th>
																																									<th>Quantity</th>
																																							</tr>
																																					</tbody>

																																	<tbody>

																																			{
																																					c.map(d => {
																																							return <tr className="statDeptContainer actionableRecord" key={d.name}>
																																											<td>{d.name}</td>
																																											<td>{this.renderScoreToName(d.name)}</td>
																																											<td>{d.value}</td>
																																										</tr>
																																					})
																																			}

																																	</tbody>


																															</table>

																												</div></div>
																											}
																									})
																								}
																								</div>
																							}
																							{
																								!this.props.locationNpsData.some( location => location.placeName.trim() === this.state.currentFilterLocation.trim() ) && <div>
																								<div className="locationSpecificReportPieSpace piechart col-md-12 reportBlockspacer" key='0'><div className="col-md-4 nps-survey-container float-left">
																													 <div>
																															<h5>{this.state.currentFilterLocation} Score</h5>
																														 <PieChart width={350} height={250} >
																														 <Pie isAnimationActive={false} data={this.state.emptySet} dataKey="value" innerRadius="60%" outerRadius="80%" startAngle={180} endAngle={-180} fill="#999999" >
																														 <Label width={100} position="center">---</Label>
																														 </Pie>
																															<Legend layout="vertical" align="right" verticalAlign="middle" />
																														 </PieChart>
																															</div>
																												</div> <div className="col-md-7 nps-survey-container float-right">
																															<h5>NPS Survey Responses</h5>
																															<table className="npsSurveyTable">
																																	<tbody>
																																			<tr>
																																					<th>Value</th>
																																					<th>Name</th>
																																					<th>Quantity</th>
																																			</tr>
																																	</tbody>

																																	<tbody>
																																			 <tr className="statDeptContainer actionableRecord" key='5'>
																																				<td>5</td>
																																				<td >Excellent</td>
																																				<td>0</td>
																																			</tr>
																																			 <tr className="statDeptContainer actionableRecord" key='4'>
																																				<td>4</td>
																																				<td>Very Good</td>
																																				<td>0</td>
																																			</tr>
																																			<tr className="statDeptContainer actionableRecord" key='3'>
																																				<td>3</td>
																																				<td>Average</td>
																																				<td>0</td>
																																			</tr>
																																			<tr className="statDeptContainer actionableRecord" key='2'>
																																				<td>2</td>
																																				<td>Dissatified</td>
																																				<td>0</td>
																																			</tr>
																																			<tr className="statDeptContainer actionableRecord" key='1'>
																																				<td>1</td>
																																				<td>Dissatified</td>
																																				<td>0</td>
																																			</tr>
																																	</tbody>
																															</table>
																												</div></div>
																																																</div>
																							}
													</div>
											}


						<div className="locationSpecificReportMiddle ">
										{this.props.endorsementsLoading && <Spinner/>}
										 <div className="col-md-4 nps-survey-container float-left">
										<div>
												<h5>Top Endorsements</h5>
										</div>
										{this.props.endorsementsLoading && <Spinner/>}
										 {this.props.endorsementsData && <div>

														{
																this.props.endorsementsData.comboBadgeData.slice(0,5).map((c,index) => {
																	let severity = false;
																	let severityName = '';
								  
																	if(c.severitylevel!==undefined && c.severitylevel!==null){
																		severity = true;
																		if(c.severitylevel==='Okay'){ severityName = 'average'; }
																		if(c.severitylevel==='Bad'){ severityName = 'bad'; }
																		if(c.severitylevel==='Great'){ severityName = 'good'; }
																	}
																		return <div className="statBadgeContainer" key={c.endorsement_id+'-'+index}>
																						<label>
																							<div className="badge-card__icon-wrapper-dashboard">
																								<img alt="" className="img-fluid" src={c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/endorsement_assets/' + c.image}/>
																								{severity ? <div className={""+'badge-card__icon-overlay-dashboard ' + severityName}></div> : null}
																							</div>
																							<p>{c.description}</p>
																						</label>
																					</div>
																})
														}
														{(this.props.endorsementsData && this.props.endorsementsData.comboBadgeData.length===0) && <p>No data to display.</p>}
										</div>
										}

									</div>


									<div className="col-md-4 middleCptContainer float-left">
										{this.props.locationOverviewLoading && <Spinner/>}
										 {this.props.locationOverviewData && <div className="totalSurveys">
												<img alt="Total Launched" src={process.env.PUBLIC_URL + '/admin_assets/launchedIcon.png'} />
												<div className="detailedInfo">
														<h3>{this.props.locationOverviewData.totalSurveys[0].count}</h3>
														<h5>Surveys Sent</h5>
												</div>
										</div> }
										{this.props.locationOverviewData && <div className="totalOpened">
												<img alt="Total Opened" src={process.env.PUBLIC_URL + '/admin_assets/openedIcon.png'} />
												<div className="detailedInfo">
														<h3>{this.props.locationOverviewData.openedSurveys[0].count}</h3>
														<h5>Surveys Opened</h5>
												</div>
										</div> }
										 {this.props.locationOverviewData && <div className="totalCompleted">
												<img alt="Total Completed" src={process.env.PUBLIC_URL + '/admin_assets/completedIcon.png'} />
												<div className="detailedInfo">
														<h3>{this.props.locationOverviewData.completedSurveys[0].count}</h3>
														<h5>Completed Fully</h5>
												</div>
										</div>
										}
									</div>


									<div className="col-md-4 nps-survey-container float-left">
										<div>
												<h5>Top Survey Senders</h5>
										</div>
										{this.props.locationOverviewLoading && <Spinner/>}
										 {this.props.locationOverviewData && <div>

														{
																this.props.locationOverviewData.senderData.slice(0,6).map(c => {
																		return <div className="statBadgeContainer" key={c.created_by}>
																						<label>
																							<p>{(c.created_by!=='null' && c.created_by!=='undefined' && c.created_by!=null ) ? c.created_by : 'Unknown'}</p>
																							<p className="senderStat">{c.total}</p>
																						</label>
																					</div>
																})
														}

										</div>
										}
										{(this.props.locationOverviewData && this.props.locationOverviewData.senderData.length===0) && <p>No data to display.</p>}
									</div>

						</div>



								{  this.props.staffEngagementData && <Table className="case-list sortable reportBadgeContainer">
										<thead>
										<tr className="reportBadgeContainer table-headings">
												<th></th>
												<th>Name</th>
												<th>Badges</th>
												<th className="text-center">Engagements</th>
										</tr>
										</thead>
										<tbody>
										{
												this.props.staffEngagementData.map((c,index) => {
														return <tr className="surveyRow statDeptContainer reportBadgeContainer" key={c.endorsement_id+'-'+index}>
																<td><img alt="" className="img-responsive" src={ c.image.startsWith('http') ? c.image : process.env.PUBLIC_URL + '/staff_assets/' + c.image}/></td>
																<td>{c.name}</td>
																<td><StaffBadges items={c.badges}/></td>
																<td className="text-center totalTdValue">{c.engagements}</td>
														</tr>
												})
										}
										</tbody>
								</Table> }
								{(!this.props.staffEngagementDataLoading && this.props.staffEngagementData.length===0) && <p className="text-center">No Results Found</p>}
								{this.props.staffEngagementDataLoading && <Spinner/>}

						</div>
						</div> }
				</div>
		}
}

const mapStateToProps = state => {
		return {
				tvClient: state.login.tvClient,
				accessToken: state.login.tvClient.accessToken,
				staffEngagementDataLoading: state.staffEngagementData.loading,
				staffEngagementData: state.staffEngagementData.engagementData,
				locations: state.locationList.locations,
				locationNpsData: state.locationNpsCpt.locationNpsData,
				locationNpsLoading: state.locationNpsCpt.locationNpsLoading,
				locationNpsMax: state.locationNpsCpt.locationNpsMax,
				endorsementsLoading: state.endorsementsCpt.endorsementsLoading,
				endorsementsData: state.endorsementsCpt.endorsementsData,
				locationOverviewData: state.locationOverviewCpt.locationOverviewData,
				locationOverviewLoading: state.locationOverviewCpt.locationOverviewLoading,
				isLocationOnly: state.login.user && state.login.user.attributes.role === 'locationonly',
				isSurveysReportsOnly: state.login.user && state.login.user.attributes.role === 'surveysreportsonly',
				roleLocations: state.login.user.attributes.locations
		};
};

const mapDispatchToProps = dispatch => {
		return {
				listLocations: (...params) => dispatch(listLocations(...params)),
				listAllStaffEngagementsReport: (...params) => dispatch(listAllStaffEngagementsReport(...params)),
				listAllStaffEngagements: (...params) => dispatch(listAllStaffEngagements(...params)),
				viewLocationNpsData: (...params) => dispatch(viewLocationNpsData(...params)),
				viewCustomLocationNpsDataByLocation: (...params) => dispatch(viewCustomLocationNpsDataByLocation(...params)),
				viewAdminDashboardEndorsementsReports: (...params) => dispatch(viewAdminDashboardEndorsementsReports(...params)),
				viewAdminLocationOverviewData: (...params) => dispatch(viewAdminLocationOverviewData(...params))
		};
};

export default connect(mapStateToProps, mapDispatchToProps)(LocationSpecificReport);
